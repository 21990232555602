import { StrapiTextualProps } from '@/features/strapi/components/textual';
import { StrapiPricingPlanCardProps } from '@/features/strapi/components/pricing-plan-card';
import { StrapiFaqProps } from '@/features/strapi/components/faq';
import { StrapiImageTextualProps } from '@/features/strapi/components/image-textual';

export const PricingMainBannerContent = {
  title: {
    size: `5rem`,
    weight: `bold`,
    text: `Start free, Pay as you scale!`,
    mobileText: `Start free, Pay as\nyou scale!`,
    mobileMultiplier: 0.65,
  } as StrapiTextualProps,
  subTitle: {
    size: `2rem`,
    weight: `normal`,
    text: `The Shopify CRO playground is for eCommerce entrepreneurs\n and mature teams ready to take control over their site optimization.`,
    mobileText: `The Shopify CRO playground is for eCommerce
    entrepreneurs and mature teams ready to take control
    over their site optimization.`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.65,
  } as StrapiTextualProps,
  benefitUsps: [
    {
      textual: {
        text: `No commitment`,
        size: `1.8rem`,
        mobileMultiplier: 0.6,
      },
    },
    {
      textual: {
        text: `CC not needed`,
        size: `1.8rem`,
        mobileMultiplier: 0.6,
      },
    },
    {
      textual: {
        text: `Cancel at anytime`,
        size: `1.8rem`,
        mobileMultiplier: 0.6,
      },
    },
  ] as StrapiImageTextualProps[],
  pricingPlans: {
    superTitle: {
      size: `1.4rem`,
      weight: `normal`,
      text: `Estimate your price`,
      mobileMultiplier: 0.9,
    } as StrapiTextualProps,
    title: {
      size: `2.4rem`,
      weight: `normal`,
      text: `How many monthly orders do you have?`,
      mobileMultiplier: 0.8,
    } as StrapiTextualProps,
    trail: {
      size: `1.7rem`,
      weight: `normal`,
      text: `14 days free trial always included`,
    } as StrapiTextualProps,
    plans: [
      {
        group: 1500,
        ordersRange: `Up to 200 Orders`,
        price: `$19`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
        ],
      },
      {
        group: 1500,
        ordersRange: `200 - 600 Orders`,
        price: `$79`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
        ],
      },
      {
        group: 1500,
        ordersRange: `600 - 1,500 Orders`,
        price: `$199`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
        ],
      },
      {
        group: 5000,
        ordersRange: `1,500 - 3,000 Orders`,
        price: `$389`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
        ],
      },
      {
        group: 5000,
        ordersRange: `3,000 - 5,000 Orders`,
        price: `$599`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
          `Dedicated CSM`,
        ],
      },
      {
        group: 30000,
        ordersRange: `5,000 - 7,500 Orders`,
        price: `$869`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
          `Dedicated CSM`,
        ],
      },
      {
        group: 30000,
        ordersRange: `7,500 - 30,000 Orders`,
        price: `$989`,
        morePrice: `+ $120 for every 1,000 orders above 7,500`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
          `Dedicated CSM`,
          `Private slack channel`,
          `Up to 10 hours of engineering support`,
          `Dedicated CRO plan`,
        ],
      },
      {
        group: 31000,
        ordersRange: `More than 30,000 Orders`,
        price: `Contact us`,
        benefits: [
          `Online support`,
          `Next-gen visual editor`,
          `Smart Personalization`,
          `Unlimited A/B testing`,
          `Unlimited product \nrecommendations and upsells`,
          `Site audit`,
          `Dedicated CSM`,
          `Private slack channel`,
          `Up to 10 hours of engineering support`,
          `Dedicated CRO plan`,
        ],
        cta: `Speak with an expert`,
      },
    ] as StrapiPricingPlanCardProps[],
  },
};

export const PricingFaqContent = {
  title: {
    size: `5rem`,
    weight: `bold`,
    text: `Questions, anyone?`,
  } as StrapiTextualProps,
  questions: {
    items: [
      {
        question: `Is there a free trial available?`,
        answer: `Yes! We offer a free trial period of 14 days so you can explore the platform's features and see if it's the right fit for your
business. The free trial will automatically start after the installation`,
      },
      {
        question: `How can I get started?`,
        answer: `Simply download the Visually app from the Shopify App Store`,
      },
      {
        question: `How does the billing work?`,
        answer: `Choose the best package that fits your scale and you will see your charges on your Shopify admin. Billing occurs on a 30-day
cycle and is proportional to usage. We will automatically sample order volume during the cycle and charge according to the sample.
Orders from the last day of the billing cycle will be charged with the following.`,
      },
      {
        question: `Do I need to speak to sales or sign a contract?`,
        answer: `We don’t ask you to sign any contracts and don’t ask for annual commitment! No credit card, contracts or talking to salespeople needed.
Simply download the app and start building experiences`,
      },
      {
        question: `Can I change my plan later?`,
        answer: `There’s no need to worry about it! We will automatically  upgrade or downgrade your plan to align with your usage.`,
      },
      {
        question: `What happens if I exceed the limits of my plan?`,
        answer: `If you exceed the limits of your plan, don't worry! We will automatically upgrade you to a higher plan to accommodate your usage.`,
      },
      {
        question: `Is my data secure?`,
        answer: `We take data security seriously. Our platform follows top industry-standard security protocols to ensure the safety and
confidentiality of your data. You can learn more about our security practices in our Security Policy.`,
      },
      {
        question: `Can I cancel my subscription at any time?`,
        answer: `Yes! You can cancel your subscription at any time, and you won't be billed for the next billing cycle.`,
      },
      {
        question: `Do you provide customer support?`,
        answer: `Absolutely! Our dedicated customer support team is available to assist you with any questions or issues you may have.
You can reach out to us through our support portal.`,
      },
      {
        question: `Do you offer CRO services?`,
        answer: `We do offer CRO guidance and assistance as part of the Premium plans, or as a stand-alone package. We also work with
leading agencies who would love to aid you`,
      },
    ],
  } as StrapiFaqProps,
};
